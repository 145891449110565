import React, { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import { AuthUserContext } from "../../conext";
import { ToastContainer, toast } from 'react-toastify';
// import { presaleAbi, presaleAddress } from "../../contract/presaleContract";
import { presaleAbi, presaleAddress } from "../../contract/NewPresale";
import { erc20ABI,GuapTokenAddressR2pip,BNBerc20ABI,BNBTokenAddressR2pip } from "../../contract/BalanceOfToken";
import { GUAPPresalecontractAddress,GUAPPresalecontractABI } from "../../contract/GuapPresale";
import { BNBpresaleAddress,BNBpresaleAbi } from "../../contract/BNBpresaleContract";

export default function Presale() {
    // const web3 = new Web3(window.ethereum);
    const [r2pipPrice, setR2pipPrice] = useState(0); // Initialize state
    const [r2pipPriceBNB, setR2pipPriceBNB] = useState(0); // Initialize state
    const [GUAPraised, setGUAPraised] = useState(0); // Initialize state
    const [BNBraised, setBNBraised] = useState(0); // Initialize state

    const [r2pipInGuap,setr2pipInGuap]= useState(0);
    const [r2pipInBNB,setr2pipInBNB]= useState(0);
    const [GuapBalance,setGuapBalance]= useState(0);
    const [BNBBalance,setBNBBalance]= useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    const [name,setName]=useState('');
    const guapchainid=71111;
    const bnbchainid=56;
    const [value1, setValue1] = useState(''); // R2PIP input value
    const [ETHcalculateValue, setETHCalculateValue] = useState(''); // ETH calculated value
    const conversionRate = r2pipPrice; // 1 R2PIP = 0.001 ETH
    const BNBconversionRate = r2pipPriceBNB; // 1 R2PIP = 0.001 ETH

    const handleR2PIPChange = (e) => {
        const r2pipValue = e.target.value;
    
        if (chainId === guapchainid || chainId === bnbchainid) {
            if (!r2pipValue || r2pipValue < 0) {
                // Reset for empty or negative input
                setValue1(r2pipValue); // Allow user to clear or input negative temporarily
                setETHCalculateValue('');
                setErrorMessage('');
            } else if (r2pipValue >= 1000 && r2pipValue <= 25000) {
                // Update R2PIP and calculate equivalent
                setValue1(r2pipValue);
                const conversionRateToUse = chainId === guapchainid ? conversionRate : BNBconversionRate;
                setETHCalculateValue((r2pipValue * conversionRateToUse).toFixed(8));
                setErrorMessage('');
            } else {
                // Allow the input but show error for out-of-range values
                setValue1(r2pipValue); // Don't block the input field
                setETHCalculateValue('');
                setErrorMessage(`Value must be between 1000 and 25000.`);
            }
        } else {
            // Handle unsupported chain
            setErrorMessage('Select the correct chain.');
        }
    };

    
    
    const { walletAddress, switchNetwork,chainId,walletProvider } = useContext(AuthUserContext);
    const web3 = new Web3(walletProvider);
    const handleSwitchNetwork = () => {
        switchNetwork(bnbchainid); // Example: Switch to Ethereum Mainnet (network ID: 1)
      };
    const presaleIntegrateContract = () => {
        const presale_Contract = new web3.eth.Contract(
            presaleAbi,
            presaleAddress
        );
        return presale_Contract;
    };
    const BNBpresaleIntegrateContract = () => {
        const BNBpresale_Contract = new web3.eth.Contract(
            BNBpresaleAbi,
            BNBpresaleAddress
        );
        return BNBpresale_Contract;
    };
    const getValue = async () => {
        try {
            if(chainId===guapchainid)
            {
            const presaleContract = presaleIntegrateContract(); // Your function to initialize the contract
            const getRates = await presaleContract.methods.price().call();
            const price = Number(getRates);
            setR2pipPrice((price/1e18).toFixed(3)); // Update the state with the fetched price
            console.log("Rate Type:", typeof getRates, "Value:", getRates);

            const Graised= await presaleContract.methods.totalEthRaised().call();
            const Graised1= Number(Graised) / 1e18;
            setGUAPraised(Graised1);
            }
            else if(chainId===bnbchainid){

                const BNBpresaleContract = BNBpresaleIntegrateContract(); // Your function to initialize the contract
                const getRatesBNB = await BNBpresaleContract.methods.price().call();
                const priceBNB = Number(getRatesBNB);      
                setR2pipPriceBNB((1 / priceBNB).toFixed(8)); // Update the state with the fetched price
              
                console.log("Rate Type:", typeof getRatesBNB, "Value:", getRatesBNB);
                const GraisedBNB= await BNBpresaleContract.methods.totalEthRaised().call();
                const Graised1BNB= Number(GraisedBNB) / 1e18;
                setBNBraised(Graised1BNB.toFixed(7))
                   
            }
            else{
                alert('Select GUAP or BNB chain')
            }

            // BALANCE OF r2pip GET FROM WALLET
            if(chainId===guapchainid){
            try {
                
                const tokenContract = new web3.eth.Contract(erc20ABI, GuapTokenAddressR2pip);
                const balance = await tokenContract.methods.balanceOf(walletAddress).call();
                const balance1= Number(balance) / 1e18;
                setr2pipInGuap(balance1);
                console.log("Balance of gld"+balance1)
                // Convert balance to human-readable format based on token decimals
               
            } catch (error) {
                console.error('Error fetching token balance:', error);
                
            }}
            else if(chainId===bnbchainid){

                try {
                
                    const tokenContract = new web3.eth.Contract(BNBerc20ABI, BNBTokenAddressR2pip);
                    const balance = await tokenContract.methods.balanceOf(walletAddress).call();
                    const balance1= Number(balance) / 1e18;
                    setr2pipInBNB(balance1);
                    console.log("Balance of gld"+balance1)

                    // Convert balance to human-readable format based on token decimals
                   
                } catch (error) {
                    console.error('Error fetching token balance:', error);
                    
                } 

            }
            // BALANCE OF r2pip GET FROM WALLET

            // BALANCE OF GUAP GET FROM WALLET
            if(chainId===guapchainid){
            const balanceGUAP = await web3.eth.getBalance(walletAddress);
            const balanceGUAP1= Number(balanceGUAP) / 1e18;
            setGuapBalance(balanceGUAP1.toFixed(4));}

            else if(chainId===bnbchainid){
            const balanceGUAP = await web3.eth.getBalance(walletAddress);
            const balanceGUAP1= Number(balanceGUAP) / 1e18;
            setBNBBalance(balanceGUAP1.toFixed(9));

            
            
        }
            // BALANCE OF GUAP GET FROM WALLET


        } catch (e) {
            console.log("e", e);
        }
    };
    

    const handleGuapPresale = async () => {
        try {
            // Create the contract instance
            const GuapPresaleContract = new web3.eth.Contract(presaleAbi, presaleAddress);
    
            // Fetch current gas price for the network
            const gasPrice = await web3.eth.getGasPrice();
    
            // Send the transaction
            await GuapPresaleContract.methods.buy().send({
                from: walletAddress, // User's wallet address
                value: ETHcalculateValue * 1e18, // Ether amount in Wei
                gasPrice: gasPrice, // Explicitly set gasPrice for legacy networks
            });
    
            console.log("Transaction successful!");
            alert("Transaction completed successfully!");
            toast.success('Token Address To Import = 0x340e00e2f092a97112fEE01dC6E45C4DF9C989eC', {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
    
            // Reload or refresh the page data
            getValue();
        } catch (error) {
            if (error.code === 4001) {
                // User rejected the transaction
                console.error("Transaction was rejected by the user.");
                alert("You cancelled the transaction.");
            } else {
                console.error("An error occurred:", error.message);
                alert("An error occurred while processing the transaction.");
            }
        }
    };
    
    const handlebnbpresale=async ()=>{
        try {
        const GuapPresaleContract = new web3.eth.Contract(BNBpresaleAbi, BNBpresaleAddress);
        
                await GuapPresaleContract.methods.buy().send({
                    from: walletAddress,
                    value: ETHcalculateValue * 1e18,
                });
        
                console.log("Transaction successful!");
                alert("Transaction completed successfully!");
                toast.success('Token Address To Import = 0xeeCC5EB2b345e143326B02E061983005bfea2045', {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                // Reload the page after the transaction completes
                getValue()
            }
            catch (error) {
                if (error.code === 4001) {
                    // User rejected the transaction
                    console.error("Transaction was rejected by the user.");
                    alert("You cancelled the transaction.");
                } else {
                    console.error("An error occurred:", error.message);
                    // alert("An error occurred while processing the transaction.");
                }
            }

    }




   
    
    useEffect(() => {
        if(chainId===bnbchainid){
            getValue();
        }
        if(chainId===guapchainid){
            getValue()
        }
        getValue();
    });

    
    
    return (
        <div className="w-full max-w-md mx-auto p-6 h-full bg-black rounded-lg shadow-lg text-white mt-2 md:mt-5">
            {/* Stage and Price Info */}
            <div className="text-center mb-4">
                <h1 className="flex justify-center tiems-center text-[30px] md:text-[40px] lg:text-[40px] w-full font-extrabold text-red1 mb-2 md:mb-5">
                    Buy now before price increase
                </h1>
            </div>

            {/* Progress Bar */}
            {/* <div className="bg-gray-300 rounded-full h-6 mb-4">
                <div className="bg-green-500 h-6 rounded-full">
                    <span className="block text-white text-sm text-right pr-2">
                        Until Next Price: $0.375
                    </span>
                </div>
            </div> */}

            {/* Raised and Price Information */}
                { (chainId===bnbchainid || chainId===guapchainid) ? (<>
                <div className="bg-gray-800 rounded-2xl p-4">
                    <div className=" text-white mb-6">

                        { chainId===guapchainid?(<>
                            <p className="flex justify-between">
                            GUAP Raised:
                            <span className="font-bold text-red1">
                                {GUAPraised}
                            </span>
                        </p>
                        {/* {tokenBalance} */}
                        <p className="flex justify-between mt-2">
                            Your R2PIP Balance:
                            <span className="font-bold text-red1">{r2pipInGuap}</span>
                        </p>
                        <p className="flex justify-between mt-2">
                            Your GUAP Balance:
                            <span className="font-bold text-red1">  {GuapBalance}</span>
                        </p></>): chainId===bnbchainid?(<>
                        <p className="flex justify-between">
                            BNB Raised:
                            <span className="font-bold text-red1">
                                {BNBraised}
                            </span>
                        </p>
                        <p className="flex justify-between mt-2">
                            Your R2PIP Balance:
                            <span className="font-bold text-red1">{r2pipInBNB}</span>
                        </p>
                        <p className="flex justify-between mt-2">
                            Your BNB Balance:
                            <span className="font-bold text-red1">{BNBBalance}</span>
                        </p>
                        
                        
                        </>):(<></>)}
                        {/* <p className='flex justify-between'>Listing Price: <span className="font-bold text-red1">$0.07</span></p> */}
                    </div>

                    {/* Token Price Info */}
                    
                    <div className="text-center mb-4">
                    { chainId===guapchainid?(
                        <p>
                            1 R2PIP ={" "}
                            <span className="font-bold">{r2pipPrice} GUAP</span>
                        </p>):chainId===bnbchainid?(
                            
                        <p>
                        1 R2PIP ={" "}
                        <span className="font-bold">{r2pipPriceBNB}  BNB</span>
                    </p>): (<><span className="font-bold">{} Select Correct Chain</span></>)
                        }
                    </div>
                

                    {/* Payment Method Selection */}
                    <div className="flex flex-col md:flex-row justify-between gap-5 w-full 4 mb-6">
                        <button
                            className={
                                chainId === guapchainid
                                    ? "bg-gray-100 border-2 border-red1 text-red1 font-bold w-full py-2 rounded-lg"
                                    : "bg-gray-100 border-2  text-green-500 font-bold w-full py-2 rounded-lg"
                            }
                            onClick={() => {
                                chainId===bnbchainid?alert("Switch To Guap")
                                :
                                setName("bnb")}
                            }
                        >
                            GUAP
                        </button>
                        <button
                            className={
                                chainId === bnbchainid
                                    ? "bg-gray-100 border-2 border-red1 text-red1 font-bold w-full py-2 rounded-lg"
                                    : "bg-gray-100 border-2  text-green-500 font-bold w-full py-2 rounded-lg"
                            }
                            onClick={() => chainId===guapchainid?alert("Switch To BNB")
                                : setName("usdt")}
                        >
                            BNB
                        </button>
                        {/* <button className="bg-gray-100 border-2 border-gray-500 text-gray-500 font-bold w-full py-2 rounded-lg">CARD</button> */}
                    </div>

                    {/* ETH Balance */}
                    {/* <div className="text-center mb-4">
                        <p className="text-gray-500">
                            ETH Balance: <span className="font-bold">0</span>
                        </p>
                    </div> */}

                    {/* Amount Input Fields */}
                    {/* <div className="flex justify-between mb-4">
                        <div className="w-1/2 pr-2">
                            <input
                                type="number"
                                placeholder="0"
                                className="w-full p-3 bg-gray-100 rounded-lg text-center text-black"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            />
                            <p className="text-center mt-2 text-gray-500">Amount in R2PIP
                            </p>
                            {error && !value && (
                                <span className="error-message">
                                    Please Enter value greater then 0
                                </span>
                            )}
                        </div>
                        <div className="w-1/2 pl-2">
                            <input
                                type="number"
                                placeholder="0"
                                className="w-full p-3 bg-gray-100 rounded-lg text-center text-black"
                                value={calculateValue}
                                readOnly
                            />
                            <p className="text-center mt-2 text-gray-500">
                            Amount in GUAP/USDT
                            </p>
                        </div>
                    </div> */}
                    <div className="flex justify-between mb-4">
                {/* Input for R2PIP */}
                <div className="w-1/2 pr-2">
                    <input
                        type="text"
                        placeholder="R2PIP"
                        className="w-full p-3 bg-gray-100 rounded-lg text-center text-black"
                        value={value1}
                        onChange={handleR2PIPChange}
                    />
                    <p className="text-center mt-2 text-gray-500">Amount in R2PIP</p>
                    {errorMessage && (
                <p className="text-center mt-2 text-red-500">{errorMessage}</p>
            )}
                </div>

                {/* Input for ETH */}
                <div className="w-1/2 pl-2">
                    <input
                        type="number"
                        placeholder="0"
                        className="w-full p-3 bg-gray-100 rounded-lg text-center text-black"
                        value={ETHcalculateValue}
                        readOnly
                    />
                    <p className="text-center mt-2 text-gray-500">{chainId===guapchainid?("Amount in GUAPX"):("Amount in BNB")}</p>
                </div>
            </div>

                    {/* <p className="text-center text-red-500 mb-6">
                        You do not have enough to pay for this transaction.
                    </p> */}

                    {/* Action Buttons */}
                    <div className="flex flex-col md:flex-row gap-5 justify-between w-full">
                        {/* <button className="bg-black text-white w-full py-3 rounded-lg font-bold">
                        Connect Wallet
                    </button> */}
                    {/* {handlePresale} */}
                        <button
                            className={`w-full py-3 rounded-lg font-bold ${
                                value1 >= 1000
                                    ? "bg-red1 text-white cursor-pointer"
                                    : "bg-gray-400 text-gray-200 cursor-not-allowed disabled"
                            }`}
                            onClick={chainId===guapchainid ?handleGuapPresale:chainId?handlebnbpresale:()=>{alert('select right chain')}}
                            disabled={value1 < 0.5}
                        >
                            Buy Now
                        </button>
                    </div>
                </div>
                </>
                ):(<>
                 <div className="min-h-screen w-full flex flex-col justify-center items-center bg-black text-center">
            <h1 className="flex justify-center  items-center text-[20px] md:text-[20px] lg:text-[20px] font-extrabold text-white mb-2 md:mb-5">
                    Select BNB OR GUAPX Chain
            </h1>
</div>

                
                </>)  }


        </div>


    );
}
