// AppKitProvider.jsx
import React from 'react';
import { createAppKit } from '@reown/appkit/react';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { mainnet, arbitrum, polygon } from '@reown/appkit/networks'; // Added Polygon for broader support

// 1. Setup QueryClient for React Query
const queryClient = new QueryClient();

// 2. Define your Project ID (Replace with your actual Project ID from Reown Cloud)
const projectId = 'a3fbde3d5222dafdf36524089d8593d5'; // Ensure you replace this with your valid Project ID

// 3. Metadata (Optional but recommended for AppKit configuration)
const metadata = {
    name: 'AppKit Example',
    description: 'A simple AppKit example integration',
    url: 'http://localhost:3000', // Ensure this matches your development URL
    icons: ['https://example.com/icon.png'], // Replace with your app's icon
  };
  

// 4. Define supported networks (Include popular networks for better wallet compatibility)
const networks = [mainnet, arbitrum, polygon];

// 5. Create Wagmi Adapter (Handles wallet configurations)
const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId,
  ssr: true, // Enables server-side rendering compatibility
});

// 6. Initialize AppKit (Sets up modal, adapters, and configuration)
createAppKit({
  adapters: [wagmiAdapter],
  networks,
  projectId,
  metadata,
  features: {
    analytics: true, // Enables analytics (Optional, based on your preference)
  },
});

// 7. AppKitProvider component to wrap the app
export function AppKitProvider({ children }) {
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
    </WagmiProvider>
  );
}
