import React, { useEffect, useState } from 'react';
import axios from 'axios';

function PaypalSuccess() {
    const [transactionReceipt, setTransactionReceipt] = useState(null);
    const [error, setError] = useState(null);


    useEffect(() => {
        // Get the query parameter 'receipt' from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const receiptParam = urlParams.get('receipt');

        if (receiptParam) {
            try {
                // Parse the transaction receipt data from the query parameter
                const parsedReceipt = JSON.parse(decodeURIComponent(receiptParam));
                setTransactionReceipt(parsedReceipt);
            } catch (error) {
                console.error("Error parsing receipt:", error);
                setError("Failed to retrieve transaction receipt.");
            }
        } else {
            setError("No receipt data found.");
        }
    }, []);
  return (
    <div className="w-full max-w-md mx-auto p-6 h-full bg-black rounded-lg shadow-lg text-white mt-2 md:mt-5">
    {/* Stage and Price Info */}
    <div className="text-center mb-4">
        <h1 className="flex justify-center tiems-center text-[30px] md:text-[40px] lg:text-[40px] w-full font-extrabold text-red1 mb-2 md:mb-5">
            CONFIRMED
        </h1>
        <div>
            <h1>Payment Successful!</h1>
        </div>
        
    </div>
</div>
  )
}

export default PaypalSuccess