import React, { createContext, useState } from "react";
import { useWeb3ModalProvider, useWeb3ModalAccount, useSwitchNetwork, useWeb3Modal } from '@web3modal/ethers/react';

export const AuthUserContext = createContext();

const AuthState = (props) => {
  const [walletAddress, setWalletAddress] = useState('');
  const { switchNetwork } = useSwitchNetwork(); // Import switchNetwork from the hook
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  return (
    <AuthUserContext.Provider
      value={{
        setWalletAddress,
        walletAddress,
        switchNetwork, // Provide switchNetwork through context
        chainId,
        isConnected,
        walletProvider
      }}
    >
      {props.children}
    </AuthUserContext.Provider>
  );
};

export default AuthState;
