import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import { AuthUserContext } from "../../conext";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import './main.css'
const PurchaseGuap = () => {
  const [guapcoinPrice, setGuapcoinPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [minimum,setminimum]=useState(0);
  const[guapavailable,setguapavailable]=useState(0);
  const { walletAddress, switchNetwork,chainId,walletProvider,isConnected} = useContext(AuthUserContext);

  useEffect(() => {
   
    // Fetch current price of GuapCoin in USD from CoinMarketCap API
    const fetchGuapcoinPrice = async () => {
      try {
        const response = await axios.get('https://api.r2pip.com/get-guapcoin-price');
        console.log(response)
        setGuapcoinPrice(response.data.price);
        setguapavailable(response.data.balanceRes)
        setminimum(response.data.message)
              
      } catch (error) {
        console.error('Error fetching GuapCoin price:', error);
      }
    };

    fetchGuapcoinPrice();
  }, []);

  const handleQuantityChange = (e) => {
    const quantity = e.target.value;

    setQuantity(quantity);
    setTotalPrice(guapcoinPrice * quantity); // Calculate total price in USD
  };

const HandleSubmit=async(e)=>{
    e.preventDefault();

    try{
        if(quantity){}else{}
    let res = await axios.post('https://api.r2pip.com/payment',{
        walletAddress,
        quantity,
        totalPrice
    })
    console.log(res)
    if(res && res.data){
        let link = res.data.links[1].href;
        window.location.href= link
    }}catch(err){
        console.log(err);
    }
}
const Notconnected=()=>{
    alert('PLEASE CONNECT YOUR WALLET')
}

  

  return (
    <>






















<div className="w-full max-w-md mx-auto p-6 h-full bg-black rounded-lg shadow-lg text-white mt-2 md:mt-5">
            {/* Stage and Price Info */}
            <div className="text-center mb-4">
                <h1 className="flex justify-center tiems-center text-[30px] md:text-[40px] lg:text-[40px] w-full font-extrabold text-red1 mb-2 md:mb-5">
                    BUY GUAP WITH PAYPAL
                </h1>
            </div>
            {/* Raised and Price Information */}
                <div className="bg-gray-800 rounded-2xl p-4">
                    <div className=" text-white mb-6">

                        <p className="flex justify-between">
                            GUAP Available :
                            <span className="font-bold text-red1">
                            {guapavailable}
                            </span>
                        </p>
                        <p className="flex justify-between">
                        Minimum GUAP You Can Buy :
                            <span className="font-bold text-red1">
                             {minimum}
                            </span>
                        </p>
                        
                        {/* <p className='flex justify-between'>Listing Price: <span className="font-bold text-red1">$0.07</span></p> */}
                    </div>

                    {/* Token Price Info */}
                    
                    <div className="text-center mb-4">
                        <p>
                            1 GUAP ={" "}
                            <span className="font-bold">{guapcoinPrice.toFixed(6)} USDT</span>
                        </p>
                    </div>
                

                    {/* Payment Method Selection */}
                    
                    <div className="flex justify-between mb-4">
                {/* Input for R2PIP */}
                <div className="w-1/2 pr-2">
                    <input
                        type="text"
                        placeholder="R2PIP"
                        className="w-full p-3 bg-gray-100 rounded-lg text-center text-black"
                        value={quantity} 
                        onChange={handleQuantityChange} 
                        
                    />
                    <p className="text-center mt-2 text-gray-500">Amount in GUAP</p>
                    </div>
                {/* Input for ETH */}
                <div className="w-1/2 pl-2">
                    <input
                        type="number"
                        placeholder="0"
                        className="w-full p-3 bg-gray-100 rounded-lg text-center text-black"
                        value={totalPrice.toFixed(8)}
                        readOnly
                    />
                    <p className="text-center mt-2 text-gray-500">"Amount in USDT"</p>
                </div>
            </div>

                    {/* <p className="text-center text-red-500 mb-6">
                        You do not have enough to pay for this transaction.
                    </p> */}

                    {/* Action Buttons */}
                    <div className="flex flex-col md:flex-row gap-5 justify-between w-full">
                        {/* <button className="bg-black text-white w-full py-3 rounded-lg font-bold">
                        Connect Wallet
                    </button> */}
                    {/* {handlePresale} */}
                    {isConnected?(<button
                            className="bg-red1 text-white w-full py-3 rounded-lg font-bold"   
                            onClick={HandleSubmit}
                        >
                            Buy Now
                        </button>):(<button
                            className="bg-red1 text-white w-full py-3 rounded-lg font-bold"   
                            onClick={Notconnected}
                        >
                            Connect Wallet
                        </button>)}
                    </div>
                </div>
        </div>  </>
  );
};

export default PurchaseGuap;
