import React, { useContext, useEffect } from 'react'
import { useWeb3ModalProvider, useWeb3ModalAccount, useSwitchNetwork, useWeb3Modal } from '@web3modal/ethers/react';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import { AuthUserContext } from './conext';

  const projectId = "7881be1f1ed1be4bbbb6078b64d06e6e";
  // Define the Sepolia and BSC chains
  const sepolia = {
    chainId: 71111,
      name: "GuapcoinX",
      currency: "GuapX",
      explorerUrl: "",
      rpcUrl: "https://rpc-mainnet-2.guapcoinx.com",
  };
  
  const bsc = {
    chainId: 56,
    name: 'Binance Smart Chain',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://bsc-dataseed.binance.org/'
  };
//   const bsc = {
//   chainId: 11155111,
//   name: 'Sepolia ETH',
//   currency: 'ETH',
//   explorerUrl: 'https://sepolia.etherscan.io',
//   rpcUrl: 'https://rpc.sepolia.org'
// };
  // Metadata for Web3Modal
  const metadata = {
    name: 'My Website',
    description: 'My Website description',
    url: 'https://mywebsite.com',
    icons: ['https://avatars.mywebsite.com/']
  };
  
  // Web3Modal configuration
  const ethersConfig = defaultConfig({
    metadata,
    auth: {
      email: false,
      socials: [],
      showWallets: true,
      walletFeatures: true
    },
    wallets:['subwallet']
  });
  
  // Initialize Web3Modal
  createWeb3Modal({
    ethersConfig,
    chains: [sepolia, bsc],
    projectId,
    enableAnalytics: true
  });
  
const WalletConnection = () => {
  const { walletProvider } = useWeb3ModalProvider();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const { switchNetwork } = useSwitchNetwork();
  
  const { setWalletAddress } = useContext(AuthUserContext);
    useEffect(()=>{
        setWalletAddress(address)
    },[address])
  return (
    <div className="d-flex justify-content-center" >
      <div className='p-0' style={{ backgroundColor: 'none', color: 'white', borderRadius: '5px' }}>
        <w3m-button />
      </div>
    </div>
  )
}

export default WalletConnection

// WalletConnectButton.jsx
// import React from 'react';

// // Import the required AppKit components
// import '@reown/appkit'; // Ensure this is included to register the <appkit-button> component globally

// export default function WalletConnection() {
//   // The <appkit-button> component is a global web component and doesn't require props for basic functionality.
//   return (
//     <div>
//       <appkit-button />
//     </div>
//   );
// }